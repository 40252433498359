import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import './event.css'

const Event = (props) => {
  window.location.replace(props.drive_url);
  return (
    <div className="page-not-found-container">
      <Helmet>
        <title>{props.title} - {props.event_date} - Elyu Sessions</title>
        <meta property="og:title" content={props.title + " - " + props.event_date + " - Elyu Sessions"} />
      </Helmet>
      <Navigation></Navigation>
      <img
        alt="image"
        src="/logo-website-200h.png"
        className="page-not-found-image"
      />
      <main className="page-not-found-main">
        <div className="page-not-found-hero section-container">
          <div className="page-not-found-max-width max-content-container">
            <div className="page-not-found-heading-container">
              <h1 className="page-not-found-text Heading1">
                {props.title}
              </h1>
              <span className="page-not-found-text01">
                <span>
                  {props.event_date}
                </span>
                <br></br>
              </span>
            </div>
          </div>
        </div>
      </main>
      <div className="section-container">
        <div className="max-content-container">
          <div className="page-not-found-top-part">
            <div className="page-not-found-links-container">
              <div className="page-not-found-product-container">
                <span className="page-not-found-text04">Packages</span>
                <span className="page-not-found-text05">Session A</span>
                <span className="page-not-found-text06">Session B</span>
                <span>Session C</span>
              </div>
              <div className="page-not-found-navigate-container">
                <span className="page-not-found-text08">Navigate</span>
                <span className="page-not-found-text09">Copyright</span>
                <span className="page-not-found-text10">Sitemap</span>
                <span>Privacy Policy</span>
              </div>
              <div className="page-not-found-contact-container">
                <span className="page-not-found-text12">Our Studio</span>
                <span className="page-not-found-text13">
                  <span>Lingsat</span>
                  <br></br>
                  <span>San Fernando City,</span>
                  <br></br>
                  <span>La Union</span>
                  <br></br>
                  <span>2500</span>
                </span>
              </div>
            </div>
            <div className="page-not-found-subscribe-container">
              <span className="page-not-found-text21">
                Follow our social media pages for more updates!
              </span>
            </div>
          </div>
        </div>
        <div className="page-not-found-separator"></div>
        <footer className="page-not-found-max-width2 max-content-container">
          <img
            alt="image"
            src="/logo-website-200h.png"
            className="page-not-found-image1"
          />
          <span className="page-not-found-text22">
            Self-Portrait &quot;Tiny&quot; Studio
          </span>
        </footer>
      </div>
    </div>
  )
}

export default Event
